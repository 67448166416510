<script>
import { required, integer, decimal, minValue, minLength, maxLength, requiredIf } from "vuelidate/lib/validators";
// import * as cloneDeep from "lodash.clonedeep";
import { POST_SUBSCRIPTION_PRICE } from "@/state/actions.type";
import cloneDeep from 'lodash.clonedeep';
import Layout from "../../layouts/main";
import * as moment from "moment";
import Breadcrumb from '../../../components/breadcrumb.vue';
import switchInput from '../../../components/switch-input.vue';

export default {
  components: {
      Layout,
      Breadcrumb,
      switchInput
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40),
      },
      description: {
        required,
      },
      frequency: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.plan_type === 'REGULAR';
        }),
        integer,
        minValue: minValue(1),
        maxLength: maxLength(5),
      },
      period: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.plan_type === 'REGULAR';
        }),
      },
      amount: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.plan_type === 'REGULAR';
        }),
        decimal,
        minValue: minValue(1),
        maxLength: maxLength(10),
      },
      currency: {
        required,
      },
      notes: {
        $each: {
          key: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.value;
            }),
          },
          value: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.key;
            }),
          },
        }
      },
      plan_type: {
        required
      }
    },
  },
  data() {
    return {
      typesubmit: false,
      form: {
        name: "",
        description: "",
        notes: null,
        frequency: 1,
        period: "",
        amount: null,
        currency: "",
        plan_type: "REGULAR",
      },
      additional_notes: false,
      isProcessing: false,
      periodOptions: [
        { text: this.$t('subscription.hour'), value: "H" },
        { text: this.$t('subscription.day'), value: "D" },
        { text: this.$t('subscription.week'), value: "W" },
        { text: this.$t('subscription.month'), value: "M" },
        { text: this.$t('subscription.quarter'), value: "Q" },
        { text: this.$t('subscription.year'), value: "Y" },
      ],
      currencies: [
        "IDR",
        "PHP",
        "SGD",
        "THB",
        "VND",
        "MYR",
        "TWD",
        "HKD",
        "KHR",
        "WST",
        "USD",
        "INR",
        "AUD",
        "EUR",
        "KRW"
      ],
    };
  },
  watch: {
    additional_notes(value) {
      if(value) {
        this.form.notes = [{
          key: "",
          value: ""
        }]
      } else {
        this.form.notes = null;
      }
    }
  },
  created() {
    this.form.currency = this.convertCountryToCurrency;
  },

  computed: {
    convertCountryToCurrency() {
      return this.$store.getters["auth/getConvertCountry"];
    },
    items() {
      return [
        {
          text: this.$t('menuitems.subscriptions.text'),
          to: { name: 'Subscription', query: {tab: 'Plan'} }
        },
        {
          text: this.$t('menuitems.plan.text'),
          active: true,
        },
      ];
    }
  },

  methods: {
    submitPrice() {
      this.isProcessing = true;
      this.typesubmit = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.isProcessing = false;
        return false;
      }

      let formData = cloneDeep(this.form);
      if(formData.notes) {
        let notes = formData.notes.length > 0 ? formData.notes.filter(el => el.key != "" && el.val != "") : null;
        formData.notes = notes && notes.length > 0 ? notes : null;
      }

      if(formData.plan_type === 'ONDEMAND') {
        delete formData.frequency;
        delete formData.period;
        delete formData.amount;
      }

      this.$store
        .dispatch(`subscriptionPrice/${POST_SUBSCRIPTION_PRICE}`, formData)
        .then(() => {
          this.$notify({
            type: "success",
            text: "Plan Created successfully.",
            closeOnClick: true,
          });
          this.isProcessing = false;
          setTimeout(() => {
            this.$router.push({name: 'Subscription', query: {tab: 'Plan'}});
          }, 1000);
        })
        .catch((error) => {
          // error
          console.log("error", error);
          this.isProcessing = false;
          this.$notify({
            type: "error",
            text: this.$store.state.notification.message,
            closeOnClick: true,
          });
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    addField() {
      this.form.notes.push({
        key: "",
        value: ""
      });
    },
    removeAdditionalNotes(index) {
      this.form.notes.splice(index, 1)
    },
    currentDate(){
      return moment().format("DD MMM,YYYY")
    },
    updateToggle() {
      if(this.form.plan_type == 'ONDEMAND'){
        this.form.plan_type = 'REGULAR'
      }else{
        this.form.plan_type = 'ONDEMAND';
        this.form.amount = null;
        this.form.frequency = 1;
        this.form.period = "";
      }
    },
  },
};
</script>

<template>
<Layout>
    <div class="d-flex" style="height: 44px;">
      <breadcrumb :items="items" :goBackTo="{ name: 'Subscription', query: {tab: 'Plan'} }" />
      <div class="ml-auto">
        <router-link class="text-chai font-16px" :to="{ name: 'Subscription', query: {tab: 'Plan'} }">
          <b-btn
            variant="white"
            :disabled="this.isProcessing"
            style="padding: 13px 16px;height: 44px;border: 1px solid #000;margin-right: 8px;"
          >
            cancel
          </b-btn>
        </router-link>
        <b-btn
          variant="primary"
          @click="submitPrice"
          style="height: 44px;padding: 13px 16px;"
        >{{ $t('common.create_plan') }}</b-btn>
      </div>
    </div>
  <div style="margin-top: 25px;">
    <form class="row">
      <div class="col-lg-8 col-8">
        <div class="top-section">
          <div class="top-section-header">
              <div style="font-size: 16px;color: #000000;font-weight: 350;">Create New Plan</div>
              <div style="font-size: 12px;color: #00000080;margin-top: 10px;font-weight: 325;">Create New Plan to collect payment from your customers.</div>
          </div>
          <div class="top-section-body" style="padding: 20px 20px 20px 10px;">
            <div class="col-lg-12">
              <div class="form-group">
                <label
                  > {{ $t('subscription.plan_name') }}<span class="text text-danger">*</span></label
                >
                <b-form-input
                  v-model.trim="$v.form.name.$model"
                  class="form-control"
                  :placeholder="$t('subscription.enter_plan_name')"
                  name="name"
                  :state="validateState('name')"
                  :class="{
                    'is-invalid': typesubmit && $v.form.name.$error,
                  }"
                />
                <div v-if="$v.form.name.$error" class="invalid-feedback">
                  <span v-if="!$v.form.name.required">{{
                    "This value is required"
                  }}</span>
                  <span v-else>{{
                    "This value is Invalid"
                  }}</span>
                </div>
              </div>

              <div class="form-group">
                <label
                  >{{ $t('subscription.description') }}<span class="text text-danger">*</span></label
                >
                <b-form-textarea
                  v-model="$v.form.description.$model"
                  class="form-control"
                  :placeholder="$t('subscription.enter_description')"
                  name="description"
                  :state="validateState('description')"
                  rows="3"
                  :class="{
                    'is-invalid': typesubmit && $v.form.description.$error,
                  }"
                />
                <div style="margin-top: 5px;">
                    <span style="color: #FC6B2D;font-size: 14px;">Note :</span><span style="opacity: 0.50;color: black;font-size: 12px;padding-left: 10px;">Maximum 100 characters are allowed.</span>
                </div>
                <div v-if="$v.form.description.$error" class="invalid-feedback">
                  <span v-if="!$v.form.description.required">{{
                    "This value is required"
                  }}</span>
                </div>
              </div>

              <div class="form-group">
                <b-form-checkbox
                  v-model="additional_notes"
                  :value="true"
                  :unchecked-value="false"
                  class="mb-2"
                >
                  {{ $t('subscription.additional_notes') }}
                </b-form-checkbox>
                <div v-if="additional_notes">
                  <div v-for="(note, index) in $v.form.notes.$each.$iter" :key="index" class="d-flex justify-content-between mb-2">
                    <div class="w-50 mr-1">
                      <b-form-input v-model="note.key.$model" placeholder="Title" trim
                        :class="{
                          'is-invalid': typesubmit && !note.key.required,
                        }"
                      ></b-form-input>
                      <div class="invalid-feedback" v-if="typesubmit && !note.key.required">This value is required</div>
                    </div>
                    <div class="w-50 mx-1">
                      <b-form-input v-model="note.value.$model" placeholder="Description" trim
                        :class="{
                          'is-invalid': typesubmit && !note.value.required,
                        }"
                      ></b-form-input>
                      <div class="invalid-feedback" v-if="typesubmit && !note.value.required">This value is required</div>
                    </div>
                    <svg style="line-height: 43px;height: 43px;cursor: pointer;"  @click="removeAdditionalNotes(index)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <g id="Iconsax/Linear/add">
                        <path id="Vector" d="M7.24264 16.2426L15.7279 7.75736M15.7279 16.2426L7.24264 7.75736" stroke="#E36464" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                    </svg>
                    <!-- <b-btn variant="danger">X</b-btn> -->
                  </div>
                </div>
                <b-btn
                  v-if="additional_notes"
                  class="bg-white border mt-2 text-chai"
                  size="sm"
                  variant="light"
                  @click="addField"
                  style="border: unset !important;padding-left: 0px;"
                >
                 + Add Field
                </b-btn>
              </div>
              <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px;"></div>
              <!-- <b-form-checkbox
                v-model="form.plan_type"
                value="ONDEMAND"
                unchecked-value="REGULAR"
                class=""
              >
                
              </b-form-checkbox> -->
              <div class="d-flex">
                  <label style="margin-bottom: 0px;" class="d-flex">{{ $t('subscription.on_demand') }}</label>
                  <switch-input style="margin-bottom: 0px;" class="ml-auto" @input="updateToggle()" :value="form.plan_type == 'ONDEMAND'" />
              </div>
              <div style="opacity: 0.5;color: black;font-size: 12px;margin-bottom: 16px;font-weight: 325;">{{ $t('subscription.enable_option') }}</div>

              <div v-if="form.plan_type === 'ONDEMAND'" style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px;margin-top: 25px;"></div>

              <div class="form-group position-relative"  v-if="form.plan_type === 'ONDEMAND'">
                <div style="display: inline-block;margin-right: 8px;width: 30%; vertical-align: top;">
                  <label
                  >{{ $t("views.payment_links.currency")
                  }}<span class="text text-danger">*</span></label
                  >
                <!-- <img
                    v-if="form.currency == 'VND'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/vietnam1.png"
                    alt=""
                />
                <img
                    v-if="form.currency == 'THB'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/thailand.jpeg"
                    alt=""
                />
                <img
                    v-if="form.currency == 'SGD'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/singapore.png"
                    alt=""
                />
                <img
                    v-if="form.currency == 'IDR'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/indonesia.png"
                    alt=""
                />
                <img
                    v-if="form.currency == 'PHP'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/philippines.png"
                    alt=""
                />
                <img
                    v-if="form.currency == 'MYR'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/malaysia.svg"
                    alt=""
                />
                <img
                    v-if="form.currency == 'TWD'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/taiwan.png"
                    alt=""
                />
                <img
                    v-if="form.currency == 'HKD'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/hong_kong.svg"
                    alt=""
                />
                <img
                    v-if="form.currency == 'KHR'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/cambodia.svg"
                    alt=""
                />
                <img
                    v-if="form.currency == 'WST'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/samoa.svg"
                    alt=""
                />
                <img
                    v-if="form.currency == 'USD'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/united_states.svg"
                    alt=""
                />
                <img
                    v-if="form.currency == 'INR'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/india.svg"
                    alt=""
                />
                <img
                    v-if="form.currency == 'AUD'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/australia.svg"
                    alt=""
                />
                <img
                    v-if="form.currency == 'KRW'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/korea.svg"
                    alt=""
                />
                <img
                    v-if="form.currency == 'JPY'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/japan.svg"
                    alt=""
                />
                <img
                    v-if="form.currency == 'EUR'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/europe.svg"
                    alt=""
                />
                <img
                    v-if="form.currency == 'AED'"
                    class="flag_images_on_demand"
                    src="@/assets/images/flags/uae.svg"
                    alt=""
                  /> -->
                  <b-form-select
                    name="currency"
                    v-model="form.currency"
                    class="form-control custom-select-for-flag"
                  >
                    <b-form-select-option value="IDR">{{
                        "IDR"
                    }}</b-form-select-option>
                    <b-form-select-option value="PHP">{{
                        "PHP"
                    }}</b-form-select-option>
                    <b-form-select-option value="SGD">{{
                        "SGD"
                    }}</b-form-select-option>
                    <b-form-select-option value="THB">{{
                        "THB"
                    }}</b-form-select-option>
                    <b-form-select-option value="VND">{{
                        "VND"
                    }}</b-form-select-option>
                    <b-form-select-option value="MYR">{{
                        "MYR"
                    }}</b-form-select-option>
                    <b-form-select-option value="TWD">{{
                        "TWD"
                    }}</b-form-select-option>
                    <b-form-select-option value="HKD">{{
                        "HKD"
                    }}</b-form-select-option>
                    <b-form-select-option value="KHR">{{
                        "KHR"
                    }}</b-form-select-option>
                    <b-form-select-option value="WST">{{
                        "WST"
                    }}</b-form-select-option>
                    <b-form-select-option value="USD">{{
                        "USD"
                    }}</b-form-select-option>
                    <b-form-select-option value="EUR">{{
                        "EUR"
                    }}</b-form-select-option>
                    <b-form-select-option value="JPY">{{
                        "JPY"
                    }}</b-form-select-option>
                    <b-form-select-option value="KRW">{{
                        "KRW"
                    }}</b-form-select-option>
                    <b-form-select-option value="AUD">{{
                        "AUD"
                    }}</b-form-select-option>
                    <b-form-select-option value="AED">{{
                        "AED"
                    }}</b-form-select-option>
                    <b-form-select-option value="INR">{{
                        "INR"
                    }}</b-form-select-option>
                  </b-form-select>
                  <div
                    v-if="$v.form.currency.$error"
                    class="text-danger font-size-12"
                  >
                      {{
                          "This value is required"
                      }}
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="form.plan_type === 'REGULAR'">
                <label>{{ $t('subscription.frequency') }}<span class="text text-danger">*</span></label>
                <div class="d-flex justify-content-between">
                  <span style="line-height: 43px;height: 43px;background: rgba(220, 218, 213, 0.30);font-size: 14px;width: 75px;text-align: center;border-radius: 12px;border: 1px #DCDAD5 solid;border-right: unset;border-top-right-radius: 0px;border-bottom-right-radius: 0px;">
                    {{ $t('subscription.every') }}
                  </span>
                  <b-form-group
                    invalid-feedback="Frequency must be positive integer"
                    :state="validateState('frequency')"
                    class="mr-2"
                  >
                    <b-form-input style="padding-left: 25px; border-top-left-radius: 0px;border-bottom-left-radius: 0px;" v-model.number="$v.form.frequency.$model" type="number" min="1" trim
                      :class="{
                        'is-invalid': $v.form.frequency.$error,
                      }"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    invalid-feedback="Period is required"
                    :state="validateState('period')"
                    class="w-70"
                  >
                    <b-form-select
                      v-model="$v.form.period.$model"
                      :options="periodOptions"
                      :class="{
                        'is-invalid': $v.form.period.$error,
                      }"
                    >
                      <template #first>
                        <b-form-select-option value="">--  {{ $t('subscription.please_select') }} --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div v-if="form.plan_type === 'REGULAR'" style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px;"></div>

              <div class="form-group" v-if="form.plan_type === 'REGULAR'">
                <div style="display: inline-block;margin-right: 8px;width: 30%; vertical-align: top;">
                  <label> {{ $t('views.payment_links.currency') }}<span class="text text-danger">*</span></label>
                  <b-form-select
                    name="currency"
                    v-model="$v.form.currency.$model"
                    class="form-control custom-select-for-flag"
                    :options="currencies"
                  >
                  </b-form-select>
                </div>
                <div style="display: inline-block;width: calc(70% - 8px); vertical-align: top;">
                  <label> {{ $t('common.amount') }}<span class="text text-danger">*</span></label>  
                  <b-form-input
                    type="number"
                    step="any"
                    v-model.number="$v.form.amount.$model"
                    :state="validateState('amount')"
                  ></b-form-input>
                  <div v-if="$v.form.amount.$error" class="invalid-feedback">
                    {{ "Amount is required and must be positive number" }}
                  </div>
                </div>
                    <!-- <div> -->
                      <!-- <img
                        v-if="form.currency == 'VND'"
                        class="flag_images"
                        src="@/assets/images/flags/vietnam1.png"
                        alt=""
                      />
                      <img
                        v-if="form.currency == 'THB'"
                        class="flag_images"
                        src="@/assets/images/flags/thailand.jpeg"
                        alt=""
                      />
                      <img
                        v-if="form.currency == 'SGD'"
                        class="flag_images"
                        src="@/assets/images/flags/singapore.png"
                        alt=""
                      />
                      <img
                        v-if="form.currency == 'IDR'"
                        class="flag_images"
                        src="@/assets/images/flags/indonesia.png"
                        alt=""
                      />
                      <img
                        v-if="form.currency == 'PHP'"
                        class="flag_images"
                        src="@/assets/images/flags/philippines.png"
                        alt=""
                      />
                      <img
                        v-if="form.currency == 'MYR'"
                        class="flag_images"
                        src="@/assets/images/flags/malaysia.svg"
                        alt=""
                      />
                      <img
                        v-if="form.currency == 'TWD'"
                        class="flag_images"
                        src="@/assets/images/flags/taiwan.png"
                        alt=""
                      />
                      <img
                        v-if="form.currency == 'HKD'"
                        class="flag_images"
                        src="@/assets/images/flags/hong_kong.svg"
                        alt=""
                      />
                      <img
                        v-if="form.currency == 'KHR'"
                        class="flag_images"
                        src="@/assets/images/flags/cambodia.svg"
                        alt=""
                      />
                      <img
                        v-if="form.currency == 'WST'"
                        class="flag_images"
                        src="@/assets/images/flags/samoa.svg"
                        alt=""
                      />
                      <img
                        v-if="form.currency == 'USD'"
                        class="flag_images"
                        src="@/assets/images/flags/united_states.svg"
                        alt=""
                      />
                      <img
                        v-if="form.currency == 'INR'"
                        class="flag_images"
                        src="@/assets/images/flags/india.svg"
                        alt=""
                      /> -->
                    <!-- </div> -->
              </div>

              <!-- <div
                class="col-lg-12 my-1 text-right bg-white"
                style="position: sticky; bottom: 0px; padding-bottom: 15px"
              >
                <div
                  style="
                    background: #fff;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    bottom: 0px;
                    opacity: 0.5;
                  "
                ></div>
                <div style="width: 90%; margin: 0 auto; position: relative">
                  <button
                    style="width: 100%"
                    type="submit"
                    :disabled="isProcessing"
                    class="btn btn-primary"
                  >
                    {{ $t('common.create_plan') }}
                  </button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-4">
        <div style="padding: 25px;box-shadow: 0px 4px 15px 1px rgba(220, 218, 213, 0.30);border-radius: 12px;border: 1px rgba(220, 218, 213, 0.50) solid;">
            <p style="font-size: 20px;color: #FC6B2D; margin-bottom: 25px;}">Plan Summary</p>
            <label style="color: black;font-size: 14px;margin-bottom: 10px;">Amount</label>
            <b-input readonly v-if="form.plan_type !== 'ONDEMAND'" :value="(form.amount ? form.amount : 0).toLocaleString('en-US', { style: 'currency', currency: form.currency })" style="border-radius: 12px;border: 1px solid #dcdad5;height: 58px;padding: 16px 24px;color: #000;font-size: 20px;line-height: 24px;"></b-input>
            <div style="font-size: 15px;" v-if="form.plan_type == 'ONDEMAND'">As Per Request</div>
            <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px; margin-top: 25px;"></div>
            <div style="margin-bottom: 16px;" class="summary-details-section">
              <div class="summary-name">Plan Name</div>
              <div class="summary-details">{{form.name}}</div>
            </div>
            <div class="summary-details-section">
              <div class="summary-name">Plan Description</div>
              <div class="summary-details">{{form.description}}</div>
            </div>
            <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px; margin-top: 25px;"></div>
            <p style="font-size: 14px;color: #000000; margin-bottom: 12px;}">Plan Details</p>
            <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; line-height:16px;">
                <div>Created At</div>
                <div style="margin-left: auto;order: 2; color: #000;">{{  currentDate() }}</div>
            </div>
            <div v-if="form.plan_type !== 'ONDEMAND'" class="summary-details d-flex" style="color: #00000080;font-size: 12px; line-height:16px; margin-top: 8px;">
                <div>Frequency</div>
                <div style="margin-left: auto;order: 2; color: #000;">Every {{form.frequency}} {{form.period}}</div>
            </div>
        </div>
    </div>
    </form>
  </div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .custom-select-for-flag {
  padding-left: 35px;
  padding-right: 35px;
}
::v-deep .flag_images {
  position: absolute;
  width: 25px;
  top: 8px;
  left: 5px;
}

::v-deep .flag_images_on_demand {
  position: absolute;
  width: 20px;
  top: 33px;
  left: 10px;
}
.top-section-header{
  background: #dcdad54d;
  padding: 18px 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 1px #DCDAD5 solid;
}
.top-section{
    box-shadow: 0px 4px 12px 1px rgba(220, 218, 213, 0.30);
    border-radius: 15px;
}
::v-deep .top-section label{
    color: black;
    font-size: 14px;
    margin-bottom: 10px;
}
.top-section .form-group{
    margin-bottom: 20px;
}
::v-deep .top-section select, ::v-deep .top-section input, ::v-deep .top-section textarea{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    color: #000;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    overflow: hidden;
    border: 1px #DCDAD5 solid;
    height: auto;
}
.top-section select{
    padding-left: 25px;
    padding-top: 13.5px;
    padding-bottom: 13.5px;
}
.top-section .flag_images{
    position: absolute;
    width: 28px;
    top: 36px;
    left: 20px;
    border-radius: 4px;
}
.summary-details-section .summary-name{
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    line-height: 20px;
}
.summary-details-section .summary-details{
    font-size: 14px;
    line-height: 20px;
}
</style>